import { Fragment } from 'react';
import { Typography } from 'components/UI/Typography';
import styles from './widget-storyline_date_and_author-default.module.scss';

export type WidgetStorylineDateAndAuthorProps = {
  readonly model: {
    readonly modifiedDate: string;
    readonly 'author-list'?: ReadonlyArray<Author>;
  };
};

type Author = {
  readonly 'author-info': {
    readonly id: string;
    readonly displayName: string;
    readonly url: string;
  };
};

const renderAuthors = (authors: ReadonlyArray<Author>) => {
  return authors.map((author, index) => {
    const isLast = index === authors.length - 1;
    const isSecondToLast = index === authors.length - 2;
    const separator = isLast ? '' : isSecondToLast ? ' und ' : ', ';

    return (
      <Fragment key={author['author-info'].id}>
        <strong>{author['author-info'].displayName}</strong>
        {separator}
      </Fragment>
    );
  });
};

const WidgetStorylineDateAndAuthor = ({ context }: { context: WidgetStorylineDateAndAuthorProps }) => {
  const { modifiedDate, 'author-list': authorList } = context.model;
  const dateToPrint = modifiedDate ? new Date(modifiedDate) : undefined;

  return (
    <Typography className={styles.root} variant="4xs" as="div">
      {dateToPrint && (
        <time className={styles.date} dateTime={modifiedDate}>
          {dateToPrint.toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}{' '}
          um{' '}
          {dateToPrint.toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
          Uhr
        </time>
      )}
      {authorList && authorList?.length > 0 && (
        <Typography variant="4xs" as="div">
          von {renderAuthors(authorList)}
        </Typography>
      )}
    </Typography>
  );
};

export default WidgetStorylineDateAndAuthor;
